var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title,"show-search":false}},[_c('template',{slot:"actions"},[(_vm.allowAction)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.command({ action: 'add' })}}},[_vm._v(" Add New Food Tag ")]):_vm._e()],1)],2),(_vm.loading)?_c('eden-loader'):[(_vm.pageData.length)?_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatToTitleCase(scope.row.name)))])]}}],null,false,2551810672)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Food tag ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.created_at, "m do, y")))])]}}],null,false,3482086087)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Date added ")])])],2),_c('el-table-column',{attrs:{"min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.updated_at, "m do, y")))])]}}],null,false,3931836690)},[_c('template',{slot:"header"},[_c('span',[_vm._v(" Date updated ")])])],2),(_vm.allowAction)?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit-write","command":{
                  action: 'edit',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Edit Food Tag")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  id: scope.row.id,
                  index: scope.$index,
                }}},[_vm._v("Delete Food Tag")])],1)],1)]}}],null,false,433818744)}):_vm._e()],1):_c('eden-content-empty',{attrs:{"text":'No food tags'}})],_c('food-tag-form',{attrs:{"show":_vm.foodTag.visibility,"action":_vm.foodTag.action,"food-tag":_vm.foodTag.data},on:{"update:show":function($event){return _vm.$set(_vm.foodTag, "visibility", $event)},"success":_vm.getFoodTags}}),_c('food-tag-delete',{attrs:{"show":_vm.showFoodTagDelete,"food-tag":_vm.foodTag.data},on:{"update:show":function($event){_vm.showFoodTagDelete=$event},"success":_vm.getFoodTags}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }