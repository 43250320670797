<template>
  <div>
    <el-dialog
      :title="`${action === 'add' ? 'Add New' : 'Edit'} Food Tag`"
      :visible.sync="setShow"
      append-to-body
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Food Tag"
              prop="foodTag"
              :rules="validateField()"
            >
              <el-input type="text" v-model="form.foodTag" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="close">Cancel</el-button>
        <el-button type="primary" @click="save" :loading="updating">{{
          action === "add" ? "Add" : "Save changes"
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tags from "@/requests/services/meal/food-tags";

export default {
  name: "FoodTagForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    foodTag: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        foodTag: "",
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (this.action === "edit") {
          this.form.foodTag = this.formatToTitleCase(this.foodTag.name);
        }
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.form.foodTag = "";
    },
    save() {
      this.action === "add" ? this.add() : this.update();
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = {
          name: this.form.foodTag,
        };
        tags
          .add(payload)
          .then((response) => {
            if (response.data.status) {
              console.log(response.data);
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.close();
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = {
          name: this.form.foodTag,
          tag_id: this.foodTag.id,
        };
        tags
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.close();
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>
