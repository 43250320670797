<template>
  <eden-confirm-dialog
    :title="'Delete Food Tag'"
    :button-type="'danger'"
    :button-text="'Delete'"
    :button-status="deleting"
    :show.sync="setShow"
    @confirm="deleteFoodTag"
  >
    <p>
      Are you sure you want to delete this food tag,
      <span class="text-bold"> {{ formatText(this.foodTag.name) }}?</span>
    </p>
  </eden-confirm-dialog>
</template>

<script>
import tags from "@/requests/services/meal/food-tags";

export default {
  name: "FoodTagDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    foodTag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    deleteFoodTag() {
      this.deleting = true;
      const payload = {
        tag_id: this.foodTag.id,
      };
      console.log(payload);
      tags
        .delete(payload)
        .then((response) => {
          console.log(response);
          if (response.data.status) {
            this.deleting = false;
            this.$message.success(response.data.message);
            this.$emit("success");
            this.setShow = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.deleting = false;
          this.setShow = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
